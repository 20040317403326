import { hot } from "react-hot-loader/root"; // need to be required before react

import React, { useEffect, useRef, useState } from "react";
import { Native } from "../../native";
import { NativeNotification } from "../../native/notification";
import { Notification } from "../../proto/lobbyze/realtime";
import DebugMenu from "./debug_menu";
import { Store } from "./store";
import { useAtom } from "jotai";
import NotificationCard from "./components/notification_card";

const NotificationApp: React.FC = () => {
  const [notif, setNotif] = useAtom(Store.notificationList);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unsub = NativeNotification.listenNotification((entry) => {
      console.log("Received notification", entry)
      setNotif((old) => {
        return [...old, entry];
      });
    });
    return () => {
      unsub.then((fn) => fn()); // Cleanup function to unsubscribe from the event listener.
    };
  }, []);

  useEffect(() => {
    const el = listRef.current;
    if (el != null) {
      setTimeout(async () => { // Run next frame after render
        const monSize = await Native.getMonitorSize();
        const minHeight = 200;
        const maxHeight = monSize.height * 0.7;
        const height = Math.min(maxHeight, Math.max(minHeight, el.clientHeight));
        Native.setWindowSize(500, height);
      }, 0);
    }
  }, [listRef, notif]);

  return (
    <div className="notification-app" ref={listRef}>
      {Store.isDevMode && <DebugMenu />}
      {notif.map((entry) => (
        <NotificationCard key={entry.iD} notification={entry} />
      ))}
    </div>
  );
};

export default hot(NotificationApp);
