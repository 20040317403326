import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import React from "react";
import { render } from "react-dom";
import { AppModule } from "./app/app.module";
import { environment } from "./environments";
import { hmrBootstrap } from "./hmr";
import { Native } from "./native";
import NotificationApp from "./react/notification";

const bootstrap = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      useJit: true,
      preserveWhitespaces: true,
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

const isProd = environment.production;

function initialize() {
  // @ts-ignore
  let isMain = !Native.IsNative() || Native.IsMainWindow();

  if (isProd) {
    enableProdMode();
  }

  if (isMain) {
    if (!isProd && module["hot"]) {
      hmrBootstrap(module, bootstrap);
    } else {
      // Standard app entrypoint
      bootstrap();
    }
    return;
  }

  let root = document.querySelector(".app > div");
  if (!root) {
    root = document
      .querySelector(".app")
      .appendChild(document.createElement("div"));
  }

  if (Native.IsNotificationWindow()) {
    if (!isProd && module["hot"]) {
      module["hot"].accept("./react/notification", () => {
        // @ts-ignore
        const NextNotificationApp = require("./react/notification").default;
        render(React.createElement(NextNotificationApp, null), root);
      });
    }
    render(React.createElement(NotificationApp, null), root);
  }
}

(async () => {
  await Native.nativeSetup();

  initialize();
})();
