import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { NativeNotification } from "../../native/notification";

export namespace Store {
  // @ts-ignore
  export const isDevMode: boolean = process.env.NODE_ENV === "development";

  export const notificationList = isDevMode
    ? atomWithStorage<NativeNotification.Entry[]>("dev/notification_list", [])
    : atom<NativeNotification.Entry[]>([]);
}
