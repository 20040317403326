import React from "react";
import { NativeNotification } from "../../native/notification";

const DebugMenu: React.FC = () => {
  return (
    <div className="debug-menu">
      <button onClick={() => NativeNotification.addDemoNotification()}>
        Add mock notification
      </button>
    </div>
  );
};

export default DebugMenu;
