// @generated by protobuf-ts 2.9.4 with parameter long_type_number,output_javascript_es2020
// @generated from protobuf file "lobbyze/blind.proto" (package "lobbyze", syntax proto3)
// tslint:disable
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class TournamentStructure$Type extends MessageType {
    constructor() {
        super("lobbyze.TournamentStructure", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "levels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Level }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.iD = 0;
        message.levels = [];
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.uint32();
                    break;
                case /* repeated lobbyze.Level levels */ 2:
                    message.levels.push(Level.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 ID = 1 [json_name = "ID"]; */
        if (message.iD !== 0)
            writer.tag(1, WireType.Varint).uint32(message.iD);
        /* repeated lobbyze.Level levels = 2; */
        for (let i = 0; i < message.levels.length; i++)
            Level.internalBinaryWrite(message.levels[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.TournamentStructure
 */
export const TournamentStructure = new TournamentStructure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Level$Type extends MessageType {
    constructor() {
        super("lobbyze.Level", [
            { no: 1, name: "level", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "blinds", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "ante", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.level = 0;
        message.blinds = 0;
        message.ante = 0;
        message.time = 0;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 level */ 1:
                    message.level = reader.int32();
                    break;
                case /* double blinds */ 2:
                    message.blinds = reader.double();
                    break;
                case /* double ante */ 3:
                    message.ante = reader.double();
                    break;
                case /* double time */ 4:
                    message.time = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* double blinds = 2; */
        if (message.blinds !== 0)
            writer.tag(2, WireType.Bit64).double(message.blinds);
        /* double ante = 3; */
        if (message.ante !== 0)
            writer.tag(3, WireType.Bit64).double(message.ante);
        /* double time = 4; */
        if (message.time !== 0)
            writer.tag(4, WireType.Bit64).double(message.time);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.Level
 */
export const Level = new Level$Type();
