// @generated by protobuf-ts 2.9.4 with parameter long_type_number,output_javascript_es2020
// @generated from protobuf file "lobbyze/tournament.proto" (package "lobbyze", syntax proto3)
// tslint:disable
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TournamentStructure } from "./blind";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf enum lobbyze.TournamentGame
 */
export var TournamentGame;
(function (TournamentGame) {
    /**
     * @generated from protobuf enum value: TournamentGame_Unknown = 0;
     */
    TournamentGame[TournamentGame["TournamentGame_Unknown"] = 0] = "TournamentGame_Unknown";
    /**
     * @generated from protobuf enum value: TournamentGame_HoldEm = 1;
     */
    TournamentGame[TournamentGame["TournamentGame_HoldEm"] = 1] = "TournamentGame_HoldEm";
    /**
     * @generated from protobuf enum value: TournamentGame_Omaha = 2;
     */
    TournamentGame[TournamentGame["TournamentGame_Omaha"] = 2] = "TournamentGame_Omaha";
    /**
     * @generated from protobuf enum value: TournamentGame_Other = 3;
     */
    TournamentGame[TournamentGame["TournamentGame_Other"] = 3] = "TournamentGame_Other";
    /**
     * @generated from protobuf enum value: TournamentGame_Omaha5 = 4;
     */
    TournamentGame[TournamentGame["TournamentGame_Omaha5"] = 4] = "TournamentGame_Omaha5";
    /**
     * @generated from protobuf enum value: TournamentGame_Omaha6 = 5;
     */
    TournamentGame[TournamentGame["TournamentGame_Omaha6"] = 5] = "TournamentGame_Omaha6";
})(TournamentGame || (TournamentGame = {}));
// @generated message type with reflection information, may provide speed optimized methods
class Tournament$Type extends MessageType {
    constructor() {
        super("lobbyze.Tournament", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 13 /*ScalarType.UINT32*/ },
            { no: 23, name: "NativeID", kind: "scalar", jsonName: "NativeID", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Currency", kind: "scalar", jsonName: "Currency", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "SiteID", kind: "scalar", jsonName: "SiteID", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "LateRegister", kind: "scalar", jsonName: "LateRegister", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "Game", kind: "enum", jsonName: "Game", T: () => ["lobbyze.TournamentGame", TournamentGame] },
            { no: 6, name: "BuyIn", kind: "scalar", jsonName: "BuyIn", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "Rake", kind: "scalar", jsonName: "Rake", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "Speed", kind: "scalar", jsonName: "Speed", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "Type", kind: "scalar", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "Players", kind: "scalar", jsonName: "Players", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "Variant", kind: "scalar", jsonName: "Variant", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "State", kind: "scalar", jsonName: "State", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "Blind", kind: "scalar", jsonName: "Blind", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 15, name: "Start", kind: "message", jsonName: "Start", T: () => Timestamp },
            { no: 16, name: "PrizePool", kind: "scalar", jsonName: "PrizePool", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 17, name: "Overlay", kind: "scalar", jsonName: "Overlay", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 18, name: "AverageDuration", kind: "scalar", jsonName: "AverageDuration", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 19, name: "InitialStack", kind: "scalar", jsonName: "InitialStack", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 20, name: "CurrentBlind", kind: "scalar", jsonName: "CurrentBlind", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 21, name: "Field", kind: "scalar", jsonName: "Field", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "Structure", kind: "message", jsonName: "Structure", T: () => TournamentStructure },
            { no: 24, name: "CurrLevelStart", kind: "message", jsonName: "CurrLevelStart", T: () => Timestamp },
            { no: 25, name: "CurrLevel", kind: "scalar", jsonName: "CurrLevel", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 26, name: "Color", kind: "scalar", jsonName: "Color", T: 13 /*ScalarType.UINT32*/ },
            { no: 27, name: "Brand", kind: "scalar", jsonName: "Brand", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.iD = 0;
        message.currency = "";
        message.siteID = 0;
        message.lateRegister = 0;
        message.game = 0;
        message.buyIn = 0;
        message.rake = 0;
        message.name = "";
        message.speed = "";
        message.type = "";
        message.players = "";
        message.variant = "";
        message.state = "";
        message.blind = 0;
        message.prizePool = 0;
        message.overlay = 0;
        message.averageDuration = 0;
        message.initialStack = 0;
        message.currentBlind = 0;
        message.field = 0;
        message.color = 0;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.uint32();
                    break;
                case /* optional string NativeID = 23 [json_name = "NativeID"];*/ 23:
                    message.nativeID = reader.string();
                    break;
                case /* string Currency = 2 [json_name = "Currency"];*/ 2:
                    message.currency = reader.string();
                    break;
                case /* uint32 SiteID = 3 [json_name = "SiteID"];*/ 3:
                    message.siteID = reader.uint32();
                    break;
                case /* double LateRegister = 4 [json_name = "LateRegister"];*/ 4:
                    message.lateRegister = reader.double();
                    break;
                case /* lobbyze.TournamentGame Game = 5 [json_name = "Game"];*/ 5:
                    message.game = reader.int32();
                    break;
                case /* double BuyIn = 6 [json_name = "BuyIn"];*/ 6:
                    message.buyIn = reader.double();
                    break;
                case /* double Rake = 7 [json_name = "Rake"];*/ 7:
                    message.rake = reader.double();
                    break;
                case /* string Name = 8 [json_name = "Name"];*/ 8:
                    message.name = reader.string();
                    break;
                case /* string Speed = 9 [json_name = "Speed"];*/ 9:
                    message.speed = reader.string();
                    break;
                case /* string Type = 10 [json_name = "Type"];*/ 10:
                    message.type = reader.string();
                    break;
                case /* string Players = 11 [json_name = "Players"];*/ 11:
                    message.players = reader.string();
                    break;
                case /* string Variant = 12 [json_name = "Variant"];*/ 12:
                    message.variant = reader.string();
                    break;
                case /* string State = 13 [json_name = "State"];*/ 13:
                    message.state = reader.string();
                    break;
                case /* double Blind = 14 [json_name = "Blind"];*/ 14:
                    message.blind = reader.double();
                    break;
                case /* google.protobuf.Timestamp Start = 15 [json_name = "Start"];*/ 15:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* double PrizePool = 16 [json_name = "PrizePool"];*/ 16:
                    message.prizePool = reader.double();
                    break;
                case /* double Overlay = 17 [json_name = "Overlay"];*/ 17:
                    message.overlay = reader.double();
                    break;
                case /* double AverageDuration = 18 [json_name = "AverageDuration"];*/ 18:
                    message.averageDuration = reader.double();
                    break;
                case /* double InitialStack = 19 [json_name = "InitialStack"];*/ 19:
                    message.initialStack = reader.double();
                    break;
                case /* double CurrentBlind = 20 [json_name = "CurrentBlind"];*/ 20:
                    message.currentBlind = reader.double();
                    break;
                case /* int32 Field = 21 [json_name = "Field"];*/ 21:
                    message.field = reader.int32();
                    break;
                case /* optional lobbyze.TournamentStructure Structure = 22 [json_name = "Structure"];*/ 22:
                    message.structure = TournamentStructure.internalBinaryRead(reader, reader.uint32(), options, message.structure);
                    break;
                case /* optional google.protobuf.Timestamp CurrLevelStart = 24 [json_name = "CurrLevelStart"];*/ 24:
                    message.currLevelStart = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.currLevelStart);
                    break;
                case /* optional int32 CurrLevel = 25 [json_name = "CurrLevel"];*/ 25:
                    message.currLevel = reader.int32();
                    break;
                case /* uint32 Color = 26 [json_name = "Color"];*/ 26:
                    message.color = reader.uint32();
                    break;
                case /* optional string Brand = 27 [json_name = "Brand"];*/ 27:
                    message.brand = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 ID = 1 [json_name = "ID"]; */
        if (message.iD !== 0)
            writer.tag(1, WireType.Varint).uint32(message.iD);
        /* optional string NativeID = 23 [json_name = "NativeID"]; */
        if (message.nativeID !== undefined)
            writer.tag(23, WireType.LengthDelimited).string(message.nativeID);
        /* string Currency = 2 [json_name = "Currency"]; */
        if (message.currency !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currency);
        /* uint32 SiteID = 3 [json_name = "SiteID"]; */
        if (message.siteID !== 0)
            writer.tag(3, WireType.Varint).uint32(message.siteID);
        /* double LateRegister = 4 [json_name = "LateRegister"]; */
        if (message.lateRegister !== 0)
            writer.tag(4, WireType.Bit64).double(message.lateRegister);
        /* lobbyze.TournamentGame Game = 5 [json_name = "Game"]; */
        if (message.game !== 0)
            writer.tag(5, WireType.Varint).int32(message.game);
        /* double BuyIn = 6 [json_name = "BuyIn"]; */
        if (message.buyIn !== 0)
            writer.tag(6, WireType.Bit64).double(message.buyIn);
        /* double Rake = 7 [json_name = "Rake"]; */
        if (message.rake !== 0)
            writer.tag(7, WireType.Bit64).double(message.rake);
        /* string Name = 8 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        /* string Speed = 9 [json_name = "Speed"]; */
        if (message.speed !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.speed);
        /* string Type = 10 [json_name = "Type"]; */
        if (message.type !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.type);
        /* string Players = 11 [json_name = "Players"]; */
        if (message.players !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.players);
        /* string Variant = 12 [json_name = "Variant"]; */
        if (message.variant !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.variant);
        /* string State = 13 [json_name = "State"]; */
        if (message.state !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.state);
        /* double Blind = 14 [json_name = "Blind"]; */
        if (message.blind !== 0)
            writer.tag(14, WireType.Bit64).double(message.blind);
        /* google.protobuf.Timestamp Start = 15 [json_name = "Start"]; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* double PrizePool = 16 [json_name = "PrizePool"]; */
        if (message.prizePool !== 0)
            writer.tag(16, WireType.Bit64).double(message.prizePool);
        /* double Overlay = 17 [json_name = "Overlay"]; */
        if (message.overlay !== 0)
            writer.tag(17, WireType.Bit64).double(message.overlay);
        /* double AverageDuration = 18 [json_name = "AverageDuration"]; */
        if (message.averageDuration !== 0)
            writer.tag(18, WireType.Bit64).double(message.averageDuration);
        /* double InitialStack = 19 [json_name = "InitialStack"]; */
        if (message.initialStack !== 0)
            writer.tag(19, WireType.Bit64).double(message.initialStack);
        /* double CurrentBlind = 20 [json_name = "CurrentBlind"]; */
        if (message.currentBlind !== 0)
            writer.tag(20, WireType.Bit64).double(message.currentBlind);
        /* int32 Field = 21 [json_name = "Field"]; */
        if (message.field !== 0)
            writer.tag(21, WireType.Varint).int32(message.field);
        /* optional lobbyze.TournamentStructure Structure = 22 [json_name = "Structure"]; */
        if (message.structure)
            TournamentStructure.internalBinaryWrite(message.structure, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.Timestamp CurrLevelStart = 24 [json_name = "CurrLevelStart"]; */
        if (message.currLevelStart)
            Timestamp.internalBinaryWrite(message.currLevelStart, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* optional int32 CurrLevel = 25 [json_name = "CurrLevel"]; */
        if (message.currLevel !== undefined)
            writer.tag(25, WireType.Varint).int32(message.currLevel);
        /* uint32 Color = 26 [json_name = "Color"]; */
        if (message.color !== 0)
            writer.tag(26, WireType.Varint).uint32(message.color);
        /* optional string Brand = 27 [json_name = "Brand"]; */
        if (message.brand !== undefined)
            writer.tag(27, WireType.LengthDelimited).string(message.brand);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.Tournament
 */
export const Tournament = new Tournament$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TournamentList$Type extends MessageType {
    constructor() {
        super("lobbyze.TournamentList", [
            { no: 1, name: "Tournament", kind: "message", jsonName: "Tournament", repeat: 1 /*RepeatType.PACKED*/, T: () => Tournament }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.tournament = [];
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated lobbyze.Tournament Tournament = 1 [json_name = "Tournament"];*/ 1:
                    message.tournament.push(Tournament.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated lobbyze.Tournament Tournament = 1 [json_name = "Tournament"]; */
        for (let i = 0; i < message.tournament.length; i++)
            Tournament.internalBinaryWrite(message.tournament[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.TournamentList
 */
export const TournamentList = new TournamentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleEntry$Type extends MessageType {
    constructor() {
        super("lobbyze.ScheduleEntry", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "Tournament", kind: "message", jsonName: "Tournament", T: () => Tournament },
            { no: 3, name: "TierName", kind: "scalar", jsonName: "TierName", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Priority", kind: "scalar", jsonName: "Priority", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.iD = 0;
        message.tierName = "";
        message.priority = 0;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.uint32();
                    break;
                case /* lobbyze.Tournament Tournament = 2 [json_name = "Tournament"];*/ 2:
                    message.tournament = Tournament.internalBinaryRead(reader, reader.uint32(), options, message.tournament);
                    break;
                case /* string TierName = 3 [json_name = "TierName"];*/ 3:
                    message.tierName = reader.string();
                    break;
                case /* int32 Priority = 4 [json_name = "Priority"];*/ 4:
                    message.priority = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 ID = 1 [json_name = "ID"]; */
        if (message.iD !== 0)
            writer.tag(1, WireType.Varint).uint32(message.iD);
        /* lobbyze.Tournament Tournament = 2 [json_name = "Tournament"]; */
        if (message.tournament)
            Tournament.internalBinaryWrite(message.tournament, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string TierName = 3 [json_name = "TierName"]; */
        if (message.tierName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tierName);
        /* int32 Priority = 4 [json_name = "Priority"]; */
        if (message.priority !== 0)
            writer.tag(4, WireType.Varint).int32(message.priority);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.ScheduleEntry
 */
export const ScheduleEntry = new ScheduleEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleList$Type extends MessageType {
    constructor() {
        super("lobbyze.ScheduleList", [
            { no: 1, name: "Schedule", kind: "message", jsonName: "Schedule", repeat: 1 /*RepeatType.PACKED*/, T: () => ScheduleEntry }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.schedule = [];
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated lobbyze.ScheduleEntry Schedule = 1 [json_name = "Schedule"];*/ 1:
                    message.schedule.push(ScheduleEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated lobbyze.ScheduleEntry Schedule = 1 [json_name = "Schedule"]; */
        for (let i = 0; i < message.schedule.length; i++)
            ScheduleEntry.internalBinaryWrite(message.schedule[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.ScheduleList
 */
export const ScheduleList = new ScheduleList$Type();
