import { isTauri } from "@tauri-apps/api/core";
import {
  currentMonitor,
  getCurrentWindow,
  LogicalSize,
  PhysicalSize,
  Window,
} from "@tauri-apps/api/window";
import Cookies from "js-cookie";
import { NativeConnection } from "./connection";
import { NativeNotification } from "./notification";

let isNative: boolean = false;
let currentWindow: Window | null = null;

let isMain: boolean = false;
let isNotification: boolean = false;

function eventCallback({ event, data }: NativeConnection.ConnectionEvent) {
  if (event === "status") {
    window.dispatchEvent(
      new CustomEvent("connection-status", { detail: data })
    );
  } else if (event === "error") {
    window.dispatchEvent(
      new CustomEvent("connection-error", { detail: data.msg })
    );
  }
}

export namespace Native {
  export function IsNative() {
    return isNative;
  }

  export function IsMainWindow() {
    return isMain;
  }

  export function IsNotificationWindow() {
    return isNotification;
  }

  export async function nativeSetup(): Promise<void> {
    isNative = isTauri();
    if (!isNative) {
      return;
    }

    currentWindow = getCurrentWindow();
    isMain = currentWindow.label === "main";
    isNotification = currentWindow.label === "notification";

    NativeConnection.listenEvent(eventCallback);

    if (isMain) {
      const authToken = Cookies.get("Authentication") || "";
      await NativeConnection.setAuthToken(authToken);
    }
  }

  export async function logout(): Promise<void> {
    if (!isNative) {
      return;
    }
    await NativeConnection.setAuthToken("");
  }

  export function setDecoration(enabled: boolean) {
    currentWindow?.setDecorations(enabled);
  }

  export function setWindowSize(width: number, height: number) {
    currentWindow?.setSize(new LogicalSize(width, height));
  }

  export async function getMonitorSize(): Promise<PhysicalSize> {
    const mon = await currentMonitor();
    if (mon == null) {
      throw "no monitor detected";
    }
    return mon.size;
  }
}
