import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { User, UserData } from '../interfaces/user';
import Cookies from '../utils/cookies';
import { Native } from '../../native';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private lastUser: UserData = null

  login() {
    Cookies.set('auth_origin', location.href)
    this.lastUser = null
    const loginUrl = new URL(environment.API_URL + 'auth', location.href)
    loginUrl.searchParams.set('return', location.href)

    const url = new URL("https://auth.lobbyze.com/auth/?app_name=Lobbyze")
    url.searchParams.set('target', loginUrl.toString())

    location.href = url.toString()
  }

  async logout() {
    await Native.logout()
    Cookies.remove('Authentication')
    Cookies.remove('Authentication', { domain: '.lobbyze.com' })
    localStorage.removeItem('sites-true');
    localStorage.removeItem('sites-false');
    this.lastUser = null
    location.href = '/'
  }

  isLoggedIn(): boolean {
    const u = this.user
    if(u == null || !u.email) {
      return false
    }
     // check if token is expired
    if(u.exp < Date.now() / 1000) {
      this.logout()
      this.login()
      return false
    }
    return true
  }

  get user() {
    if(this.lastUser != null) {
      return this.lastUser
    }
    const cookie = Cookies.get('Authentication')
    if (!!cookie) {
      const data = atob(cookie.split(' ')[1].split('.')[1])
      this.lastUser = JSON.parse(data) as UserData
      return this.lastUser
    } else {
      return null
    }
  }

  get token() {
    return Cookies.get('Authentication') || ''
  }
}
