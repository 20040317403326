import { invoke } from "@tauri-apps/api/core";
import { listen, UnlistenFn } from "@tauri-apps/api/event";

export namespace NativeConnection {
  export enum Status {
    Disconnected = "Disconnected",
    Connecting = "Connecting",
    Connected = "Connected",
  }

  export type ConnectionEvent =
    | {
        event: "error";
        data: {
          msg: string;
        };
      }
    | {
        event: "status";
        data: {
          status: Status;
        };
      };

  export async function getStatus(): Promise<Status> {
    return await invoke<Status>("connection_get_status");
  }

  export async function setAuthToken(authToken: String): Promise<void> {
    return await invoke("connection_set_auth_token", { authToken });
  }

  export async function getLastError(): Promise<string | null> {
    return await invoke("connection_get_last_error");
  }

  export async function listenEvent(
    callback: (ConnectionEvent) => void
  ): Promise<UnlistenFn> {
    return await listen<ConnectionEvent>("connection/event", (e) =>
      callback(e.payload)
    );
  }
}
