import React from "react";
import { NativeNotification } from "../../../native/notification";
import * as Proto from "../../../proto/lobbyze/realtime";

type Props = {
  notification: NativeNotification.Entry;
};

const NotificationCard: React.FC<Props> = ({ notification }) => {
  return (
    <div className="notification-card">
      <pre>{Proto.Notification.toJsonString(notification, { prettySpaces: 4 })}</pre>
    </div>
  );
};

export default NotificationCard;
